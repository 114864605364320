<template>
  <div v-for="(food, index) in cards" :key="index">
    <FoodCard :food-data="food" />
  </div>
</template>

<script>
import FoodCard from "../components/FoodCard.vue";
export default {
  name: "FoodMenuList",
  components: {
    FoodCard,
  },
  data() {
    return {
      cards: [
        {
          title: "Bonjour, comment ça va? , LET'S START THE DAY WITH A CROISSANT",
          name: "Butter Croissant",
          price: "$3.00",
          name02: "Almond Croissant",
          price02: "$4.00",
          name03: "Chocolate Croissant",
          price03: "$3.50",
          name04: "Ham Croissant",
          price04: "$7.50",
          description04: "Croissant filled with Ham,Cheese,Tomato & Cucumber",
          name05: "Mona's Croissant",
          price05: "$9.00",
          description05:
            "Croissant filled with Smoked Salmon,Horseradish,CreamCheese & Cucumber",
        },
        {
          title: "Sweet Crêpes and Dessert",
          titleDescription:
            "Add sliced Strawberries for $1.50, Bananas or extra whipped Cream for each $1.00",
          name: "Classic Sugar Crêpe",
          price: "$4.50",
          name02: "Lemon Crêpe",
          price02: "$8.00",
          description02: "Fresh Lemon, White Chocolate Cream & Shredded Coconut",
          name03: "Lady Marmalade Crêpe",
          price03: "$8.00",
          description03: "Orange Marmalade, Chocolaté Sauce & whipped Cream",
          name04: "Nutella Crêpe",
          price04: "$9.50",
          description04: "Nutella with whipped Cream",
          name05: "Crêpe Safari",
          price05: "$12.00",
          description05: "Nutella, Banana, Strawberry with whipped Cream",
          name06: "Apfelstrudel Crêpe",
          price06: "$ 12.00",
          description06:
            "Apple Purée, dried Raisins, Cinnamon, Vanilla Ice Cream with whipped Cream",
          name07: "Homemade French Cake",
          description07: "ask for the Cake of the Day",
          price07: "$ 5.00",
          name08: "Cake with Ice Cream",
          price08: "$ 6.00",
          name09: "Crème Brulée",
          price09: "$ 10.00",
        },
        {
          title: "Savory Crêpe",
          name: "French Riviera",
          description: "Ham, Cheese, Tomato and Oregano ",
          price: "$11.90",
          name02: "Coqueline",
          price02: "$13.50",
          description02: "Thin sliced Roast Chicken, Cheese. Red Onion & Mushroom",
          name03: "Jardinière",
          price03: "$13.90",
          description03: "Cheese, Tomato, Avocado, Mushroom, Bell Pepper & Red Onion",
          name04: "Norvégienne",
          price04: "$15.50",
          description04: "Smoked Salmon, Green Onion, Sour Cream & Avocado",
          name05: "Trois Fromages",
          price05: "$13.90",
          description05: "SwissCheese, American Cheese& GoatCheese, toppedwith an Egg",
        },
        {
          title: "Home-made Quiche",
          name: "Quiche Lorraine Slice",
          description: "Ham & Bacon with Cheese",
          price: "$11.90",
          name03: "Quiche Florentine Slice",
          price03: "$11.90",
          description03: "Spinach with Cheese",
          name04: "Combo (For Lunch only)",
          price04: "$14.90",
          description04: "Bowl of Soup& your Choice of Quiche",
          suggestion05:
            "You can preorder your entire quiche (11 inches) Lorraine or Florentine and pick it up the next day",
          suggestionPrice05: "$27.00",
        },
        {
          title: "Hot Sandwiches",
          name: "Panini Marlon",
          price: "$9.50",
          description: "French Baguette, Ham, Cheese. Tomato & Oregano",
          name02: "	Panini St Tropez",
          price02: "$10.50",
          description02:
            "French Baguette, Thin sliced Roast Chicken, Cheese, Mushroom & Tomato",
          description03: "(Panini: For Lunch only)",
          name04: "Croque Monsieur",
          price04: "$10.50",
          description04: "Smoked Ham & Cheese on bread",
          name05: "Croque Hawaii",
          price05: "$10.90",
          description05: "Ham & Cheese. Pineapple baked on bread",
          name06: "Croque Madame",
          price06: "$11.90",
          description06: "Ham & Cheese, Onion & Tomato baked on bread topped with an Egg",
        },
        {
          title: "Traditional Burger",
          titleDescription: "Comes with French Fries",
          name: "The Classic",
          description: "Classic Bun, 6oz. Burger, Lettuce, Pickles, Tomato, Red Onion",
          price: "$14.50",
          name02: "Cheeseburger",
          price02: "$15.50",
          description02:
            "Sesame Seed Bun, 6oz. Burger, Lettuce, Pickles, Tomato, Red Onion with Cheese",
        },
        {
          title: "This is so French",
          image: require("../assets/images/drapeau_fr.png"),
          name: "Bowl of French Onion Soup",
          price: "$12.00",
          description: "made with White Wine",
          name02: "Classic Omelette",
          price02: "$10.50",
          description02: "with Ham and Cheese (For Lunch only)",
          name03: "Steak & Frites",
          price03: "$25.50",
          description03:
            "Sirloin Steak 8oz with French Frites and Sauce Hollandaise or Blue Cheese Sauce",
          price04: "$15.50",
          name04: "You prefer Fishinstead of Steak? Crispy breaded Fish Fillets",
          name05: "Steak & Gratin Dauphinois",
          description05:
            "Sirloin Steak 8oz and baked Potatoes with Cream & Cheese with Sauce Hollandaise or Blue Cheese Sauce",
          price05: "$ 27.50",
          name06: "With crispy breaded Fish Fillets",
          price06: "$17.50",
          name07: "Steak & Purée",
          price07: "$27.50",
          description07:
            "Sirloin Steak 8oz and home-made Potatoes with Sauce Hollandaise or Blue Cheese Sauce",
          name09: "With crispybreadedFish Fillets",
          price09: "$17.50",
          name10: "Boeuf Bourguignon (For Dinner only)",
          price10: "$28.50",
          description10: "Beef Stew braised in Red Wine with home-made mashed Potatoes",
        },
        {
          title: "Germans, here we come",
          image: require("../assets/images/drapeau_ger.png"),
          name: "Frikadellen & Kartoffelwürfel",
          price: "$16.50",
          description: "Home-made German Meatballswith Potato Cubes and Romaine Lettuce",
          name02: "Sauerkraut & Bratwurst",
          price02: "$20.50",
          description02: "Artisanal Kraut, home made mashed potatoes, German Sausage",
        },
        {
          title: "Side Orders",
          name: "Side Salad",
          price: "$3.50",
          description: "with your choiceof Dressing, Balsamic Vinaigrette or Ranch",
          name02: "Bowl of Soup",
          description02: "ask for the Soup of the Day",
          price02: "$4.50",
          name03: "Basket of home-made French Fries",
          price03: "$ 5.00",
          name04: "Fromage",
          description04: "Cheese plate",
          price04: "$ 17.00 2 ppl $ 30.00 4 ppl",
          name05: "Charcuterie",
          price05: "$ 15.00 2 ppl $ 27.00 4 ppl",
          description05: "Cold meat plate",
          name06: "Giant Pretzel",
          price06: "$ 11.00 2 ppl / $ 19.50 4 ppl",
          description06: " Classic / Pizza (preorder or allow 30 min preparation time)",
          image02: require("../assets/images/Logo_2.png"),
        },
      ],
    };
  },
};
</script>

<style></style>
